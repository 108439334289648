import {
	useCallback, useEffect, useRef 
} from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useRouter } from "next/router";

import { useFunnelStore } from "@/store/FunnelStore";
import { track } from "@/helpers/analytics";
import { FunnelRoutes, useFunnelNavigator } from "@/hooks/useFunnelNavigator";
import { Fields } from "@/types/pet";
import { useUserDataStore } from "@/store/UserDataStore";

import { useFunnelStepHelper } from "../useFunnelStepHelper";

// RADIO BUTTON OPTIONS
export const DietOptions = [
	{
		id: "kibble biscuits",
		label: "Kibble",
		info: "Little brown biscuits",
	},
	{
		id: "wet food",
		label: "Wet food",
		info: "Tins or trays",
	},
	{
		id: "raw",
		label: "Raw",
		info: "Uncooked meats",
	},
	{
		id: "home prepared meals",
		label: "Home prepared",
		info: "Cooked by yourself at home",
	},
	{
		id: "pre-cooked food",
		label: "Pre-cooked",
		info: "Cooked and frozen",
	},
];

type FormValues = {
	diets: { diet: typeof DietOptions[number]["id"][] | undefined }[];
};

export const useDiet = () => {
	const [funnelData, setPropertyWithId] = useFunnelStore((state) => {
		return [state.data, state.setPropertyWithId];
	});

	const hasSetInitialValues = useRef(false);

	const { isReady, query, push } = useRouter();

	const user = useUserDataStore((state) => state.user);

	const { setFunnelDataFromPet } = useFunnelStepHelper();

	const { goToNextStep, buttonBarProps, stageNo, totalSteps } =
		useFunnelNavigator({
			stepName: FunnelRoutes.DIET,
		});

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
		control,
	} = useForm<FormValues>({ mode: "onChange" });

	const hasSetScrollToTop = useRef(false);

	const { fields, append } = useFieldArray<FormValues>({
		control,
		name: "diets",
	});

	useEffect(() => {
		if (!isReady) {
			return;
		}

		if (hasSetInitialValues.current) {
			return;
		}

		const { id } = query;
		// to force the type to string (it won't be anything else)
		const activePetId = (id || "") as string;

		if (!activePetId) {
			hasSetInitialValues.current = true;
			if (!funnelData[0] && Object.values(user?.pets || {}).length < 1) {
				push("../signup");

				return;
			} else if (!funnelData[0] && user.pets) {
				setFunnelDataFromPet(Object.values(user.pets)[0]);

				return;
			}
			append({
				diet: funnelData?.[0]?.[Fields.DIET],
			});
			
			return;
		}

		const activePetData = funnelData.find(
			({ name }) => name?.toLowerCase() === activePetId.toLowerCase()
		);

		append({
			diet: activePetData?.[Fields.DIET],
		});

		hasSetInitialValues.current = true;
	}, [
		append,
		funnelData,
		isReady,
		push,
		query,
		setFunnelDataFromPet,
		setValue,
		user.pets
	]);

	useEffect(() => {
		track("Funnel Section Complete", {
			stage: "Diet",
			type: "step",
			stageNo,
		});
	}, [stageNo]);

	const onSubmit = useCallback(
		(data: FormValues) => {
			data.diets.forEach(({ diet }, index) => {
				const id = funnelData[index][Fields.PETID];

				if (!id) {
					return;
				}

				setPropertyWithId(id, { diet });
			});

			goToNextStep();
		},
		[
			funnelData,
			goToNextStep,
			setPropertyWithId
		]
	);

	const value = watch("diets");

	// Page loads at the bottom as it appends content, we want to wait until
	// the field values have been set then set scroll position to top, only on once of course.
	useEffect(() => {
		if (
			value &&
			value.length === funnelData.length &&
			!hasSetScrollToTop.current
		) {
			window.scrollTo({ top: 0,
				behavior: "instant" });
			hasSetScrollToTop.current = true;
		}
	}, [funnelData.length, value]);

	const onChange = useCallback(
		(newVal: typeof DietOptions[number]["id"], index: number) => {
			if (!value || !Array.isArray(value)) {
				setValue(`diets.${index}.diet`, [newVal]);
				
				return;
			}

			if (value[index].diet && value[index].diet?.includes(newVal)) {
				const values = value[index].diet?.filter((item) => item !== newVal);
				setValue(`diets.${index}.diet`, values);
				
				return;
			}

			setValue(`diets.${index}.diet`, [...(value[index].diet || []), newVal]);
		},
		[setValue, value]
	);

	const canSubmit = !!value?.every(({ diet }) => diet && diet.length > 0);

	return {
		handleSubmit,
		onSubmit,
		options: DietOptions,
		register,
		errors,
		onChange,
		setValue,
		value,
		buttonBarProps,
		stageNo,
		totalSteps,
		fields,
		funnelData,
		canSubmit,
	};
};
